<template>
    <b-sidebar
        id="add-new-centro-trabajo-sidebar"
        :visible="isAddNewMovGastosSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="
            (val) => $emit('update:is-add-new-mov-gastos-sidebar-active', val)
        "
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
            >
                <h5 class="mb-0">Registrar Nuevo Gasto</h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <validation-provider
                        #default="validationContext"
                        name="Fecha"
                        rules="required"
                    >
                        <b-form-group label="Fecha" label-for="fecha">
                            <b-form-input
                                id="fecha"
                                type="date"
                                v-model="gastoData.fecha"
                                :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                        #default="validationContext"
                        name="Malla"
                        rules="required"
                    >
                        <b-form-group label="Malla" label-for="malla_id">
                            <b-form-select
                                id="malla_id"
                                v-model="gastoData.malla_id"
                                :options="mallas"
                                :state="getValidationState(validationContext)"
                                trim
                            >
                            </b-form-select>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                        #default="validationContext"
                        name="Tipo Gasto"
                        rules="required"
                    >
                        <b-form-group
                            label="Tipo de Gasto"
                            label-for="cat_gasto_id"
                        >
                            <b-form-select
                                id="cat_gasto_id"
                                v-model="gastoData.cat_gasto_id"
                                :options="cat_gastos"
                                :state="getValidationState(validationContext)"
                                trim
                            >
                            </b-form-select>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Password -->
                    <validation-provider
                        #default="validationContext"
                        name="Cantidad"
                        rules="required"
                    >
                        <b-form-group label="Cantidad" label-for="cantidad">
                            <b-form-input
                                id="cantidad"
                                v-model="gastoData.cantidad"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Registrar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import axios from "@axios";
import flatpickr1 from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr1.localize(Spanish); // default locale is now Spanish
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        BFormSelect,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        flatPickr,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewMovGastosSidebarActive",
        event: "update:is-add-new-mov-gastos-sidebar-active",
    },
    props: {
        isAddNewMovGastosSidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            mallas: [],
            cat_gastos: [],
            required,
            alphaNum,
            email,
            countries,
        };
    },
    setup(props, { emit }) {
        const blankData = {
            fecha: null,
            cantidad: null,
            malla_id: null,
            cat_gasto_id: null,
        };

        const gastoData = ref(JSON.parse(JSON.stringify(blankData)));
        const resetgastoData = () => {
            gastoData.value = JSON.parse(JSON.stringify(blankData));
        };

        const onSubmit = () => {
            axios
                .post("/api/movimientos-gastos", gastoData.value)
                .then((response) => {
                    //this.$toast.success('Movimiento guardado correctamente');
                    emit("refetch-data");
                    emit("update:is-add-new-mov-gastos-sidebar-active", false);
                    //return to back
                    //this.$router.push('/movimientos/captura-movimientos');
                })
                .catch((error) => {
                    //this.$toast.error('Ocurrio un error al guardar el movimiento');
                });
        };

        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetgastoData);

        return {
            gastoData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
    mounted() {
        this.getMallas(), this.getCatGastos();
    },
    methods: {
        getCatGastos() {
            axios
                .get("https://api.sushijimmysan.com/api/dragon/cat-gastos")
                .then((response) => {
                    this.cat_gastos = response.data.data.map((item) => {
                        return {
                            value: item.Codigo,
                            text: item.Nombre,
                        };
                    });
                    this.cat_gastos.unshift({
                        value: null,
                        text: "Selecciona una tipo de gasto",
                    });
                });
        },
        getMallas() {
            axios
                .get("https://api.sushijimmysan.com/api/dragon/cat-sucursales")
                .then((response) => {
                    this.mallas = response.data.data.map((item) => {
                        return {
                            value: item.Codigo,
                            text: item.Nombre,
                        };
                    });
                    this.mallas.unshift({
                        value: null,
                        text: "Selecciona una malla",
                    });
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
